import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Header/Header";
import { FaRegHeart } from "react-icons/fa";
import "./Products.css";
import { BiSitemap } from "react-icons/bi";
import Filter from "../../components/Filter/Filter";

function ProductPage() {
  const { Category } = useParams();
  const [shoes, setShoes] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const containerRef = useRef(null);
  const [filteredShoes, setFilteredShoes] = useState([]);

  useEffect(() => {
    fetch(`http://127.0.0.1:8000/api/product-page/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setShoes(data.products);
        setFilteredShoes(
          data.products?.filter((shoe) => shoe.Article.includes(Category))
        );
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, []);

  const handleFilterChange = (filters) => {
    const { colors, leathers, soles, constructions } = filters;
    const filtered = shoes?.filter((shoe) => {
      const colorMatch =
        colors.length === 0 ||
        Object.keys(shoe.Images).some((color) => colors.includes(color));
      const leatherMatch =
        leathers.length === 0 ||
        Object.keys(shoe.Leather).some((leather) => leathers.includes(leather));
      const soleMatch =
        soles.length === 0 ||
        Object.keys(shoe.Sole).some((sole) => soles.includes(sole));
      const constructionMatch =
        constructions.length === 0 ||
        Object.values(shoe.Sole).some((sole) =>
          Object.keys(sole).some((construction) =>
            constructions.includes(construction)
          )
        );

      return colorMatch && leatherMatch && soleMatch && constructionMatch;
    });

    setFilteredShoes(filtered);
  };

  function getItems() {
    return filteredShoes.reduce((total, pro) => {
      const colors = Object.keys(pro.Images);
      return total + colors.length;
    }, 0);
  }

  const scrollLeft = () => {
    containerRef.current.scrollBy({
      top: 0,
      left: -200,
      behavior: "smooth",
    });
  };

  const scrollRight = () => {
    containerRef.current.scrollBy({
      top: 0,
      left: 200,
      behavior: "smooth",
    });
  };

  const filteredShoes2 = shoes?.filter((shoe) =>
    shoe.Article.includes(Category)
  );

  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
  };

  const images = [
    "/images/test-shoes1.png",
    "/images/test-shoes1.png",
    "/images/test-shoes1.png",
  ];

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [direction, setDirection] = useState("enter"); // Track whether the image is entering or exiting

  useEffect(() => {
    const interval = setInterval(() => {
      setIsAnimating(true);
      setDirection("exit"); // First, exit the current image

      setTimeout(() => {
        setCurrentImageIndex((prevIndex) =>
          prevIndex === images.length - 1 ? 0 : prevIndex + 1
        );
        setDirection("enter"); // Then, enter the new image
        setTimeout(() => {
          setIsAnimating(false);
        }, 1000); // Complete animation duration
      }, 1000); // Animation timing for the exit
    }, 7000); // Change the image every 3 seconds

    return () => clearInterval(interval); // Clean up the interval on component unmount
  }, [images.length]);

  return (
    <>
      <Header />

      {/* shoes introduction */}
      <div className="w-full flex justify-center">
        <div className="h-[60vh]  w-[90%] flex justify-center items-center  overflow-hidden">
          <div className="flex flex-row-reverse justify-center h-full items-center overflow-hidden ">
            <div className="w-3/5 flex items-center justify-end relative">
              <div className="carousel-container">
                <div
                  className={`carousel-image-wrapper flex items-center ${
                    isAnimating ? direction : ""
                  }`}
                  key={currentImageIndex}
                >
                  <img
                    className="carousel-image drop-shadow-[0_10px_10px_rgba(0,0,0,0.4)]"
                    src={images?.[currentImageIndex]}
                    alt={`Image ${currentImageIndex + 1}`}
                  />
                </div>
              </div>
              <div className="absolute left-40 -top-10">
                {" "}
                <button
                  class="rolling-button"
                  onClick={() => scrollToSection("products")}
                >
                  <p className="button__text absolute inset-0">
                    <span style={{ transform: `rotate(${23 * 0}deg)` }}>E</span>
                    <span style={{ transform: `rotate(${23 * 1}deg)` }}>X</span>
                    <span style={{ transform: `rotate(${23 * 2}deg)` }}>P</span>
                    <span style={{ transform: `rotate(${23 * 3}deg)` }}>L</span>
                    <span style={{ transform: `rotate(${23 * 4}deg)` }}>O</span>
                    <span style={{ transform: `rotate(${23 * 5}deg)` }}>R</span>
                    <span style={{ transform: `rotate(${23 * 6}deg)` }}>E</span>
                    <span style={{ transform: `rotate(${20 * 7}deg)` }}> </span>
                    <span style={{ transform: `rotate(${20 * 8}deg)` }}> </span>
                    <span style={{ transform: `rotate(${25 * 9}deg)` }}>N</span>
                    <span style={{ transform: `rotate(${25 * 10}deg)` }}>
                      O
                    </span>
                    <span style={{ transform: `rotate(${25 * 11}deg)` }}>
                      W
                    </span>
                    <span style={{ transform: `rotate(${20 * 12}deg)` }}>
                      {" "}
                    </span>
                    <span style={{ transform: `rotate(${20 * 13}deg)` }}>
                      {" "}
                    </span>
                  </p>

                  <div class="button__circle">
                    <svg
                      viewBox="0 0 14 15"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="button__icon"
                      width="14"
                    >
                      <path
                        d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                        fill="currentColor"
                      ></path>
                    </svg>

                    <svg
                      viewBox="0 0 14 15"
                      fill="none"
                      width="14"
                      xmlns="http://www.w3.org/2000/svg"
                      class="button__icon button__icon--copy"
                    >
                      <path
                        d="M13.376 11.552l-.264-10.44-10.44-.24.024 2.28 6.96-.048L.2 12.56l1.488 1.488 9.432-9.432-.048 6.912 2.304.024z"
                        fill="currentColor"
                      ></path>
                    </svg>
                  </div>
                </button>
              </div>
            </div>
            <div className="flex flex-col items-start justify-center text-[#313131] w-2/5  h-full  rounded-md overflow-hidden bg-white">
              {/* <div className=" bg-gradient-to-r from-[#4A0E05] to-[#a77c4c] h-52  w-1.5 ml-1.5"></div> */}
              <div className="w-full flex flex-col items-start justify-center mt-5">
                <div className="text-5xl font-bold text-start uppercase ">
                  <p class="text-transparent text-8xl bg-clip-text bg-gradient-to-r from-[#241912] to-[#68431c] ">
                    {Category} COLLECTION
                  </p>
                </div>
                <p className="pt-10  text-start text-base font-semibold ml-1.5">
                  {Category === "Boots" &&
                    `Unique collection of Boots for men, handcrafted by our veteran
            leather artisans and carefully stitched with our four generation
            technique using top quality calf leather and exotic skin. Our
            collection includes Chelsea boots, Chukka boots, Work boots, Jodhpur
            boots, split-toe boots and many more!`}
                </p>
                {/* <button
                type="submit"
                class="flex justify-center gap-2 items-center mx-auto  text-lg  relative z-10 px-4 py-2 overflow-hidden  group"
              >
                Explore Now!
                <svg
                  class="w-8 h-8 justify-end group-hover:rotate-90 group-hover:bg-gray-50 text-gray-50 ease-linear duration-300 rounded-full border border-gray-700  p-2 rotate-45"
                  viewBox="0 0 16 19"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 18C7 18.5523 7.44772 19 8 19C8.55228 19 9 18.5523 9 18H7ZM8.70711 0.292893C8.31658 -0.0976311 7.68342 -0.0976311 7.29289 0.292893L0.928932 6.65685C0.538408 7.04738 0.538408 7.68054 0.928932 8.07107C1.31946 8.46159 1.95262 8.46159 2.34315 8.07107L8 2.41421L13.6569 8.07107C14.0474 8.46159 14.6805 8.46159 15.0711 8.07107C15.4616 7.68054 15.4616 7.04738 15.0711 6.65685L8.70711 0.292893ZM9 18L9 1H7L7 18H9Z"
                    class="fill-gray-800 group-hover:fill-gray-800"
                  ></path>
                </svg>
              </button> */}
                {/* <button
                  class="learn-more-button scale-75 mt-3"
                  onClick={() => scrollToSection("products")}
                >
                  <span class="circle" aria-hidden="true">
                    <span class="icon arrow"></span>
                  </span>
                  <span class="learn-more-button-text">Explore More</span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* category wise shoes */}
      <div
        className="h-[35vh]  w-full flex items-center justify-center  overflow-hidden  p-5"
        id="category-shoes"
      >
        <div className="w-full h-full flex flex-col justify-center items-center gap-y-6">
          <div className="flex items-center justify-center ">
            {/* <img className="w-8 h-8" src="/images/category-icon.png" alt="" /> */}
            <p className="text-2xl text-center font-medium">
              <span>Explore our Categories</span>
            </p>
          </div>
          <div className="relative w-full flex justify-center items-center overflow-hidden">
            <button
              className="absolute left-0 p-2 hover:bg-gray-700 transition-all duration-500 hover:text-white text-slate-400 z-10"
              onClick={scrollLeft}
            >
              &#8249;
            </button>
            <div
              className="flex gap-x-6 overflow-x-auto whitespace-nowrap no-scrollbar"
              ref={containerRef}
            >
              {filteredShoes2?.map((pro, index) => {
                const colors = Object.keys(pro.Images);
                return colors?.slice(0, 1)?.map((color, colorIndex) => (
                  <div
                    className="transition-all p-4 h-40 w-40 duration-500 ease-in-out"
                    key={`${pro.Article}-${colorIndex}`}
                  >
                    <a href={`/products/${pro.Article}`}>
                      <div className="category-card flex flex-col justify-center items-center ">
                        <img
                          src={`${pro?.Images?.[color]?.Side1}`}
                          alt={pro.Category}
                          className="h-28 w-28"
                        />
                      </div>
                      <div className="text-center font-medium text-xs uppercase mt-3">
                        {pro.Category}
                      </div>
                    </a>
                  </div>
                ));
              })}
            </div>
            <button
              className="absolute right-0 p-2  hover:bg-gray-700 transition-all duration-500 hover:text-white text-slate-400 z-10"
              onClick={scrollRight}
            >
              &#8250;
            </button>
          </div>
        </div>
      </div>

      <div className="flex gap-2 justify-start" id="products">
        <div className="mx-20 p-4 min-h-screen w-full">
          {/* filter  */}
          <div className="flex justify-between items-center text-sm font-normal w-full">
            <Filter onFilter={handleFilterChange} />
          </div>
          {/* products */}
          <div className="grid grid-cols-4 gap-4 py-10">
            {filteredShoes?.map((pro, index) => {
              const colors = Object.keys(pro.Images);
              return colors?.slice(0, 2)?.map((color, colorIndex) => (
                <div
                  className="hover:border-2 hover:border-gray-300 rounded-md m-4 hover:shadow-md relative border-2 border-white"
                  onMouseEnter={() => setHoveredIndex(`${index}-${colorIndex}`)}
                  onMouseLeave={() => setHoveredIndex(null)}
                  key={`${pro.Article}-${colorIndex}`}
                >
                  <div className="absolute top-6 right-6">
                    <FaRegHeart size={25} className="cursor-pointer" />
                  </div>
                  <a href={`/products/${pro.Article}/${color}`} className="">
                    <div className="flex flex-col justify-center items-center">
                      <img
                        src={
                          hoveredIndex === `${index}-${colorIndex}`
                            ? `${pro?.Images?.[color]?.Side1}`
                            : `${pro?.Images?.[color].Front}`
                        }
                        alt={pro.Category}
                        className="transition-all h-60 w-60 duration-500 ease-in-out"
                      />
                      <div className="text-center font-semibold text-base">
                        {pro.Category}
                      </div>
                    </div>
                  </a>
                </div>
              ));
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductPage;
