import React from "react";
import "./Welcome.css";
import { TiTick } from "react-icons/ti";

function Welcome() {
  return (
    <>
      <div className="pt-[70px] welcome-background h-screen">
        <div className="w-[1240px] mx-auto">
          <div className="flex justify-between items-center">
            <div className="w-1/2">
              <p className="text-white font-light py-5 text-lg Quicksand">
                We directly source leather from tanneries and collaborate with
                leading shoe material producers for cost efficiency.
              </p>
              <p className="text-white font-light py-5 text-lg Quicksand">
                Customize your shoes, play around with material and color
                combinations, with our 3D design software.
              </p>
              <p className="text-white font-light py-5 text-lg Quicksand">
                Upload your existing designs and analyze the cost-benefit with
                our AI cost analysis tool in real-time.
              </p>
            </div>
            <div className="w-1/2">
              <img
                src="https://mtofactory.com/wp-content/uploads/2019/11/ipad.png"
                alt=""
                className="h-[400px] m-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <div
        class="uncode-divider-wrap uncode-divider-wrap-bottom z_index_0 uncode-divider-relative uncode-row-divider-invert"
        style={{ height: "150px" }}
        data-height="150"
        data-unit="px"
      >
        <svg
          version="1.1"
          class="uncode-row-divider uncode-row-divider-curve"
          x="0px"
          y="0px"
          width="100%"
          height="150px"
          viewBox="0 0 240 24"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
        >
          {/* <!-- Define the gradient --> */}
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop
                offset="9%"
                style={{ stopColor: "#0176f8", stopOpacity: "1" }}
              />
              <stop
                offset="91%"
                style={{ stopColor: "#6442ff", stopOpacity: "1" }}
              />
            </linearGradient>
          </defs>
          {/* <!-- Background rectangle with the color you want to fill outside --> */}
          <rect x="0" y="0" width="240" height="24" fill="url(#grad1)" />

          {/* <!-- Paths with transparency or a different color --> */}
          <path
            fill="#ffffff"
            fill-opacity="0.6"
            d="M0,24V0C47.91,29.42,92.93,28.712,239.512,0H240v23.98L0,24z"
          ></path>
          <path
            fill="#ffffff"
            fill-opacity="0.4"
            d="M0,23.98v-14.361C34.971,30.26,95.62,27.501,240,2.797V23.98H0L0,23.98z"
          ></path>
          <path
            fill="#ffffff"
            d="M0,23.98v-7.359C33.652,29.278,97.769,24.918,240,5.447V23.98H0L0,23.98z"
          ></path>
        </svg>
      </div>
    </>
  );
}

export default Welcome;
