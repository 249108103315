import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home/Home";
import ProductPage from "../pages/Products/ProductPage";
import Register from "../pages/Register/Register.jsx";
import ProductPage2 from "../pages/Products/ProductPage2";
import ListingPage from "../admin/ListingPage";

function AllRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/products_page/:Category" element={<ProductPage />} />
        <Route path="/products/:Article/:Color" element={<ProductPage2 />} />
        <Route path="/adminDashboard/listingPage" element={<ListingPage />} />
        <Route path="/register" element={<Register />} />
      </Routes>
    </>
  );
}

export default AllRoutes;
