import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa6";

const ColorSelect = ({ options, selectedColor, onColorChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);
  const handleSelect = (color) => {
    onColorChange(color);
    setIsOpen(false);
  };

  // Function to handle clicks outside the popup
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Effect to add and clean up the event listener
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const displayColor =
    typeof selectedColor === "string" ? selectedColor : "Colors";
  const colorDisplay =
    displayColor !== "Colors" ? displayColor?.split("_") : ["Select Color"];

  return (
    <div className="relative">
      <button
        className="border-2 rounded-md p-2 w-[170px] cursor-pointer flex justify-between items-center"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="flex items-center">
          {displayColor !== "Colors" && (
            <div
              className="w-5 h-5 border rounded-full"
              style={{ backgroundColor: colorDisplay[1] }}
            />
          )}
          <span className="ml-2">
            {colorDisplay.length > 1
              ? colorDisplay?.slice(0, -1)
              : colorDisplay[0]}
          </span>
        </div>
        <span>
          <FaChevronDown size={10} />
        </span>
      </button>
      {isOpen && (
        <div
          className="absolute bg-white border rounded-md w-full z-10"
          ref={popupRef}
        >
          {Object.entries(options)?.map(([color, price], index) => (
            <div
              key={index}
              className="flex items-center p-2 hover:bg-gray-200 cursor-pointer"
              onClick={() => handleSelect(color)}
            >
              <div
                className="w-5 h-5 border rounded-full"
                style={{ backgroundColor: color?.split("_")[1] }}
              />
              <div className="flex justify-between items-center w-full">
                {color?.split("_")[0]}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ColorSelect;
