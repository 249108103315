import React, { useState, useEffect } from "react";
import { FaChevronDown, FaUserCircle } from "react-icons/fa";
import "./Header.css";

function Header() {
  const [navHeight, setNavHeight] = useState(false);
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [showNav, setShowNav] = useState(true);
  const [show, setShow] = useState(false);
  const [shoes, setShoes] = useState([]);
  const [hoveredContent, setHoveredContent] = useState("");

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > 70) {
        setNavHeight(true);

        if (currentScrollTop < lastScrollTop) {
          setShowNav(true);
        } else {
          setShowNav(false);
        }

        setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
      } else {
        setNavHeight(false);
        setShowNav(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollTop]);

  useEffect(() => {
    fetch(`http://127.0.0.1:8000/api/product-page/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setShoes(data.products);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, []);
  
  const uniqueShoes = shoes?.filter(
    (shoe, index, self) =>
      index ===
      self.findIndex(
        (s) =>
          s.Article?.split("_")?.slice(0, -1).join("_") ===
          shoe.Article?.split("_")?.slice(0, -1).join("_")
      )
  );

  return (
    <>
      <nav
        className={`fluid-container mx-auto w-full ${
          !navHeight ? "border-b-[#0bacc5]" : ""
        } px-10 sticky top-0 h-fit z-50 bg-white flex justify-between items-center border-b shadow-sm transition-all duration-150 ${
          showNav ? "translate-y-0" : "-translate-y-full"
        }`}
      >
        {/* website logo */}
        <div className="w-[400px] flex align-center justify-start">
          <a href="/">
            <span className="font-semibold text-3xl hover:text-[#8c8c8c]">
              <span className="font-bold">BUWCH </span>
              Lifestyles
            </span>
          </a>
        </div>
        {/* website logo */}

        {/* menu-items */}
        <div className="w-[700px] flex justify-between items-center">
          <a
            href="/"
            className={`${
              window.location.pathname === "/" ? "text-[#8c8c8c]" : ""
            } font-semibold text-[14px] hover:text-[#8c8c8c] py-5`}
          >
            HOME
          </a>
          <div className="menu-item hover:border-b-2 hover:border-b-black transition-all duration-75">
            <a
              href="/"
              className={`${
                window.location.pathname === "/products" ? "text-[#8c8c8c]" : ""
              } font-semibold text-[14px] hover:text-[#8c8c8c] py-5 flex items-center justify-center`}
            >
              PRODUCTS <FaChevronDown className="ms-1" />
            </a>
            <section className="sub-menu-container absolute w-screen left-0 py-3">
              <div className="px-3 w-[20%]">
                {uniqueShoes?.map((pro, index) => (
                  <div className="flex justify" key={index}>
                    <div className="border-l-2 px-3 border-l-gray-400 text-sm hover:font-bold font-normal text-gray-400 hover:text-gray-900 hover:border-l-gray-900">
                      <a
                        href={`/products_page/${pro.Article?.split("_")
                          ?.slice(0, -1)
                          .join("_")}`}
                        onMouseEnter={() =>
                          setHoveredContent(
                            Object.values(pro?.Images)?.[0]?.Main
                          )
                        }
                        onMouseLeave={() => setHoveredContent("")}
                      >
                        <div className="flex justify-start items-center">
                          <img
                            src={`${Object.values(pro?.Images)?.[0]?.Main}`}
                            alt={pro.Article?.split("_")
                              ?.slice(0, -1)
                              .join("_")}
                            className="h-12 w-12"
                          />

                          <span className="ml-4 text-sm">
                            {pro.Article?.split("_")?.slice(0, -1).join("_")}
                          </span>
                        </div>
                      </a>
                    </div>
                  </div>
                ))}
              </div>
              <div className="px-3 w-[20%]">
                <div className="bg-gray-200 w-full h-full flex items-center justify-center">
                  {hoveredContent !== "" && (
                    <div className="">
                      <img
                        alt=""
                        src={`${hoveredContent}`}
                        className="h-60 w-60 "
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="px-3 w-[20%]">
                <h5 className="border-b-2 border-b-black inline ">
                  Featured Product
                </h5>
                <p className="mt-3">
                  <ul>
                    <li>LIMITED EDITION SHOES BLACK LOAFERS </li>
                    <li>NEW COLLECTION</li>
                    <li>CHELSEA BOOTS MEN</li>
                    <li>CARMINA X ABSOLUTE BESPOKE</li>
                    <li>TASSEL LOAFER</li>
                    <li>SUNLINED SHOE</li>
                    <li>SOUR BEST SELLERS</li>
                    <li>BLACK DRESS SHOES</li>
                    <li>BLACK CHELSEA BOOTSEEE</li>
                  </ul>
                </p>
              </div>
              <div className="px-3 w-[20%]">
                <h5 className="border-b-2 border-b-black inline ">
                  Featured Product
                </h5>
                <p className="mt-3">
                  <ul>
                    <li>LIMITED EDITION SHOES BLACK LOAFERS </li>
                    <li>NEW COLLECTION</li>
                    <li>CHELSEA BOOTS MEN</li>
                    <li>CARMINA X ABSOLUTE BESPOKE</li>
                    <li>TASSEL LOAFER</li>
                    <li>SUNLINED SHOE</li>
                    <li>SOUR BEST SELLERS</li>
                    <li>BLACK DRESS SHOES</li>
                    <li>BLACK CHELSEA BOOTSEEE</li>
                  </ul>
                </p>
              </div>
              <div></div>
            </section>
          </div>
          <a
            href="/"
            className={`${
              window.location.pathname === "" ? "text-[#8c8c8c]" : ""
            } font-semibold text-[14px] hover:text-[#8c8c8c] py-5`}
          >
            FAQS
          </a>
          <a
            href="/"
            className={`${
              window.location.pathname === "" ? "text-[#8c8c8c]" : ""
            } font-semibold text-[14px] hover:text-[#8c8c8c] py-5`}
          >
            CONTACT
          </a>
          <a
            href="/"
            className={`${
              window.location.pathname === "" && !navHeight
                ? "text-[#8c8c8c]"
                : ""
            } font-semibold text-[14px] hover:text-[#8c8c8c] py-5`}
          >
            BACKOFFICE
          </a>
          <a
            href="/register"
            className={`${
              window.location.pathname === "" ? "text-[#8c8c8c]" : ""
            } font-semibold text-[14px] hover:text-[#8c8c8c] hover:border-[#8c8c8c] border px-7 py-3 rounded-sm ${
              navHeight && "border-black"
            }`}
          >
            CREATE ACCOUNT
          </a>
          <button className="cursor-pointer" onClick={() => setShow(!show)}>
            <FaUserCircle className="h-10 w-10" />
          </button>
          {show && (
            <>
              <button className="w-max text-black text-sm font-semibold p-2 absolute bg-[#E0F2FE] right-8 -bottom-8 border rounded-md pop-up-animation duration-200">
                Login
              </button>
            </>
          )}
        </div>
        {/* menu-items */}
      </nav>
    </>
  );
}

export default Header;
