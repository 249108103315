import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header";
import { RiImageEditFill } from "react-icons/ri";
import "./admin.css";
import { IoMdAdd } from "react-icons/io";
import ColorSelect from "./ColorSelect";
import { CgCloseR } from "react-icons/cg";
import SelectDropdown from "../components/Dropdown";
import SelectDropdown2 from "../components/Dropdown2";

function ListingPage() {
  const [showMaterialCost, setShowMaterialCost] = useState([]);
  const [product, setProduct] = useState({
    article: "",
    category: "",
    images: [
      {
        color: "",
        images: { Main: "", Back: "", Side: "", Side1: "", Side2: "" },
      },
    ],
    colors: [{ color: "" }],
    leathers: [{ leather: "", finishes: [] }],
    leatherConsumption: null,
    soles: [{ sole: "", constructions: [{ construction: "", welt: [] }] }],
  });

  // Fetching material cost data from API
  useEffect(() => {
    fetch(`http://127.0.0.1:8000/api/get-material-cost/`, {
      method: "POST",
      body: JSON.stringify({}),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        setShowMaterialCost(data.materials);
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  }, []);

  // Handle adding more sections (Images, Colors, Leathers, Soles)
  const handleAddMore = (type) => {
    setProduct((prevState) => {
      let newItem;
      switch (type) {
        case "Image":
          newItem = {
            color: "",
            images: { Main: "", Back: "", Side: "", Side1: "", Side2: "" },
          };
          return { ...prevState, images: [...prevState.images, newItem] };
        case "Colors":
          newItem = { color: "" };
          return { ...prevState, colors: [...prevState.colors, newItem] };
        case "Leathers":
          newItem = { leather: "", finishes: [] };
          return { ...prevState, leathers: [...prevState.leathers, newItem] };
        case "Soles":
          newItem = {
            sole: "",
            constructions: [{ construction: "", welt: [] }],
          }; // Allow multiple constructions
          return { ...prevState, soles: [...prevState.soles, newItem] };
        case "Constructions":
          newItem = { construction: "", welt: [] }; // Add construction under selected sole
          const updatedSoles = [...prevState.soles];
          updatedSoles[prevState.soles.length - 1].constructions.push(newItem); // Push to the last added sole
          return { ...prevState, soles: updatedSoles };
        default:
          return prevState;
      }
    });
  };

  // Handle removing a section
  const handleRemove = (type, soleIndex, constructionIndex = null) => {
    setProduct((prevState) => {
      let updatedState = { ...prevState };

      if (type === "constructions" && constructionIndex !== null) {
        // Handle removing constructions within soles
        const updatedSoles = [...prevState.soles];
        updatedSoles[soleIndex].constructions = updatedSoles[
          soleIndex
        ].constructions.filter((_, i) => i !== constructionIndex);
        updatedState.soles = updatedSoles;
      } else {
        // Handle removing colors, leathers, or soles
        updatedState[type] = updatedState[type].filter(
          (_, i) => i !== soleIndex
        );
      }

      return updatedState;
    });
  };

  // Handle image uploads
  const handleImageUpload = (e, position, index) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProduct((prevState) => {
          const updatedImages = [...prevState.images];
          updatedImages[index].images[position] = reader.result;
          return { ...prevState, images: updatedImages };
        });
      };
      reader.readAsDataURL(file);
    }
  };

  // Submit the data to the catalogue collection
  const handleSubmit = () => {
    fetch(`http://127.0.0.1:8000/api/list-products/`, {
      method: "POST",
      body: JSON.stringify({ product }),
      headers: {
        "Content-type": "application/json; charset=UTF-8",
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        return response.json();
      })
      .then((data) => {
        alert(data.success);
        // setProduct({
        //   article: "",
        //   category: "",
        //   images: [
        //     {
        //       color: "",
        //       images: { Main: "", Back: "", Side: "", Side1: "", Side2: "" },
        //     },
        //   ],
        //   colors: [{ color: "" }],
        //   leathers: [{ leather: "", finishes: [] }],
        //   leatherConsumption: null,
        //   soles: [
        //     { sole: "", constructions: [{ construction: "", welt: [] }] },
        //   ],
        // });
      })
      .catch((error) => {
        console.error("Error: ", error);
      });
  };

  return (
    <div className="bg-gray-100">
      <Header />
      {/* listing page */}
      <div className="mx-20 px-20 p-4 min-h-screen">
        <div className="flex justify-start pt-6 gap-10 items-start">
          {/* Upload Images */}
          <div className="">
            {product.images?.map((upload, index) => (
              <div
                key={upload.id}
                className="px-2 bg-white border-0 shadow-md rounded-md mb-2 relative"
              >
                {/* If there are more color sections, show a remove button */}
                {index > 0 && (
                  <button>
                    <CgCloseR
                      size={20}
                      className="absolute top-2 right-2"
                      onClick={() => handleRemove("images", index)}
                    />
                  </button>
                )}

                <div>
                  <ColorSelect
                    options={
                      showMaterialCost.find((item) => item.Colors)?.Colors || {}
                    }
                    selectedColor={upload.color}
                    onColorChange={(color) => {
                      setProduct((prevState) => {
                        const updatedImages = [...prevState.images];
                        updatedImages[index].color = color;
                        return { ...prevState, images: updatedImages };
                      });
                    }}
                  />
                </div>
                <div className="flex justify-start items-start">
                  <div className="p-1">
                    <div className="flex flex-col">
                      {["Back", "Front"]?.map((position) => (
                        <div
                          key={position}
                          className="cursor-pointer my-2 relative profile-img-container w-20 h-20 mx-auto flex items-center justify-center border"
                        >
                          <input
                            type="file"
                            accept="image/*"
                            className="absolute w-full h-full opacity-0 cursor-pointer"
                            onChange={(e) =>
                              handleImageUpload(e, position, index)
                            }
                          />
                          {upload.images[position] ? (
                            <img
                              src={upload?.images[position]}
                              alt={`${position} preview`}
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <span className="pencil-icon flex flex-col items-center justify-center text-center">
                              <span>
                                <RiImageEditFill size={18} />
                              </span>
                              <span className="text-sm">{position} Image</span>
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="p-1 flex flex-col">
                    <div>
                      <div className="cursor-pointer w-44 h-44 flex mt-2 items-center justify-center border ">
                        <input
                          type="file"
                          accept="image/*"
                          className="absolute w-44 h-44 opacity-0 cursor-pointer"
                          onChange={(e) => handleImageUpload(e, "Main", index)}
                        />
                        {upload?.images?.Main ? (
                          <img
                            src={upload?.images?.Main}
                            alt="Main preview"
                            className="w-full h-full object-cover"
                          />
                        ) : (
                          <span className="pencil-icon flex flex-col items-center justify-center">
                            <span>
                              <RiImageEditFill size={25} />
                            </span>
                            <span className="text-sm">Main Image</span>
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="p-1">
                    <div className="flex flex-col">
                      {["Side1", "Side2"]?.map((position) => (
                        <div
                          key={position}
                          className="cursor-pointer my-2 relative profile-img-container w-20 h-20 mx-auto flex items-center justify-center border"
                        >
                          <input
                            type="file"
                            accept="image/*"
                            className="absolute w-full h-full opacity-0 cursor-pointer"
                            onChange={(e) =>
                              handleImageUpload(e, position, index)
                            }
                          />
                          {upload?.images?.[position] ? (
                            <img
                              src={upload?.images?.[position]}
                              alt={`${position} preview`}
                              className="w-full h-full object-cover"
                            />
                          ) : (
                            <span className="pencil-icon flex flex-col items-center justify-center text-center">
                              <span>
                                <RiImageEditFill size={18} />
                              </span>
                              <span className="text-sm">{position} Image</span>
                            </span>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}

            {/* Add More Colors Button */}
            <div className="flex justify-end py-3">
              <button
                className="bg-blue-400 text-white border-blue-400 shadow-lg hover:shadow-sm rounded-md p-2 flex justify-between items-center text-sm"
                onClick={() => handleAddMore("Image")}
              >
                <IoMdAdd className="mr-2" /> More Colors
              </button>
            </div>
          </div>

          {/* Upload details */}
          <div className="p-2 w-full border-0 shadow-md bg-white rounded-md">
            {/* Shoe style */}
            <div className="flex">
              {/* style */}
              <div className="p-2 w-1/2">
                <SelectDropdown
                  options={
                    showMaterialCost.find((item) => item.Styles)?.Styles || {}
                  }
                  selectedItem={product.article}
                  onItemChange={(e) => setProduct({ ...product, article: e })}
                />
              </div>
              {/* category */}
              <div className="p-2 w-1/2">
                <input
                  type="text"
                  placeholder="Category Name"
                  className="border-2 rounded-md p-2 w-full"
                  value={product.category}
                  onChange={(e) =>
                    setProduct({ ...product, category: e.target.value })
                  }
                />
              </div>
            </div>
            {/* choose colors */}
            <h6 className="px-2 py-0">Colors Available:</h6>
            <div className="grid grid-cols-6 grid-flow-row gap-4 pb-2">
              {product.colors?.map((upload, index) => (
                <div className="relative" key={index}>
                  {index > 0 && (
                    <button>
                      <CgCloseR
                        size={20}
                        className="absolute top-0 right-0"
                        onClick={() => handleRemove("colors", index)}
                      />
                    </button>
                  )}
                  <ColorSelect
                    options={
                      showMaterialCost.find((item) => item.Colors)?.Colors || {}
                    }
                    selectedColor={upload.color}
                    onColorChange={(color) => {
                      const updatedColors = [...product.colors];
                      updatedColors[index].color = color;
                      setProduct({ ...product, colors: updatedColors });
                    }}
                  />
                </div>
              ))}
              <div className="px-2 mx-2">
                <button
                  onClick={() => handleAddMore("Colors")}
                  className="border-2 border-dashed text-blue-400 rounded-md p-2 w-full border-blue-300 cursor-pointer"
                >
                  <span>+ Add More</span>
                </button>
              </div>
            </div>

            {/* choose leathers */}
            <div className="grid grid-cols-4 grid-flow-row gap-4">
              {product.leathers?.map((upload, index) => (
                <div key={index} className="p-2">
                  <div className="relative border-2 rounded-md p-2 w-full bg-blue-300">
                    {index > 0 && (
                      <button>
                        <CgCloseR
                          size={20}
                          className="absolute top-0 right-0"
                          onClick={() => handleRemove("leathers", index)}
                        />
                      </button>
                    )}
                    <SelectDropdown
                      options={
                        showMaterialCost.find((item) => item.Leathers)
                          ?.Leathers || {}
                      }
                      selectedItem={upload.leather} // Track the leather selection
                      onItemChange={(leather) => {
                        const updatedLeathers = [...product.leathers];
                        updatedLeathers[index].leather = leather; // Update leather
                        setProduct({ ...product, leathers: updatedLeathers });
                      }}
                    />
                    <div className="py-2">
                      <label>Finishing:</label>
                      <div className="flex flex-col gap-2">
                        {showMaterialCost.find((item) => item.Finishings)
                          ?.Finishings &&
                          Object.entries(
                            showMaterialCost.find((item) => item.Finishings)
                              .Finishings
                          )?.map(([finishing], finIndex) => (
                            <div key={finIndex} className="ms-2 text-sm flex">
                              <input
                                type="checkbox"
                                className="me-1"
                                id={`finishing-${index}-${finIndex}`}
                                value={finishing}
                                checked={upload.finishes.includes(finishing)} // Check for selection
                                onChange={(e) => {
                                  const updatedLeathers = [...product.leathers];
                                  if (e.target.checked) {
                                    updatedLeathers[index].finishes.push(
                                      finishing
                                    ); // Add finishing
                                  } else {
                                    updatedLeathers[index].finishes =
                                      updatedLeathers[index].finishes.filter(
                                        (f) => f !== finishing
                                      ); // Remove finishing
                                  }
                                  setProduct({
                                    ...product,
                                    leathers: updatedLeathers,
                                  });
                                }}
                              />
                              <label
                                htmlFor={`finishing-${index}-${finIndex}`}
                                className="mr-2 flex justify-between items-center w-full"
                              >
                                {finishing.replace(/_/g, " ")}
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
              <div className="p-2 w-1/2">
                <button
                  className="border-2 border-dashed text-blue-400 rounded-md p-2 w-full border-blue-300 cursor-pointer"
                  onClick={() => handleAddMore("Leathers")}
                >
                  <span>+ Add More</span>
                </button>
              </div>
            </div>

            {/* leather consumption */}
            <div className="p-2 w-1/2">
              <input
                type="number"
                placeholder="Leather Consumption"
                className="border-2 rounded-md p-2 w-full"
                value={product.leatherConsumption}
                onChange={(e) =>
                  setProduct({ ...product, leatherConsumption: e.target.value })
                }
              />
            </div>

            {/* choose soles */}
            <div className="grid grid-cols-2 grid-flow-row gap-4 pb-2">
              {product.soles?.map((soleItem, soleIndex) => (
                <div
                  key={soleIndex}
                  className="border-2 relative rounded-md p-2 w-full bg-blue-300"
                >
                  {soleIndex > 0 && (
                    <button>
                      <CgCloseR
                        size={20}
                        className="absolute top-0 right-0"
                        onClick={() => handleRemove("soles", soleIndex)}
                      />
                    </button>
                  )}

                  <SelectDropdown2
                    options={
                      showMaterialCost.find((item) => item.Soles)?.Soles || {}
                    }
                    selectedItem={soleItem.sole} // Bind the sole to this dropdown
                    onItemChange={(selectedSole) => {
                      const updatedSoles = [...product.soles];
                      updatedSoles[soleIndex].sole = selectedSole; // Update sole selection
                      setProduct({ ...product, soles: updatedSoles });
                    }}
                  />

                  <div className="py-2">
                    <h6 className="mb-2 text-lg">Construction:</h6>
                    <div className="grid grid-cols-2 grid-flow-row gap-4 pb-2">
                      {soleItem.constructions?.map(
                        (constructionItem, constructionIndex) => (
                          <div
                            key={constructionIndex}
                            className="border-2 relative rounded-md p-2 w-full bg-blue-300"
                          >
                            {constructionIndex > 0 && (
                              <button>
                                <CgCloseR
                                  size={20}
                                  className="absolute top-0 right-0"
                                  onClick={() =>
                                    handleRemove(
                                      "constructions",
                                      soleIndex,
                                      constructionIndex
                                    )
                                  }
                                />
                              </button>
                            )}

                            <SelectDropdown
                              options={
                                showMaterialCost.find(
                                  (item) => item.Constructions
                                )?.Constructions || {}
                              }
                              selectedItem={constructionItem.construction} // Bind construction
                              onItemChange={(selectedConstruction) => {
                                const updatedSoles = [...product.soles];
                                updatedSoles[soleIndex].constructions[
                                  constructionIndex
                                ].construction = selectedConstruction;
                                setProduct({ ...product, soles: updatedSoles });
                              }}
                            />

                            {/* Welts Section */}
                            <div className="flex flex-col gap-2 mt-2">
                              {showMaterialCost.find((item) => item.Welts)
                                ?.Welts &&
                                Object.entries(
                                  showMaterialCost.find((item) => item.Welts)
                                    .Welts
                                ).map(([welt], weltIndex) => (
                                  <div
                                    key={weltIndex}
                                    className="ms-5 text-sm flex"
                                  >
                                    <input
                                      type="checkbox"
                                      className="me-1"
                                      id={`welt-${soleIndex}-${constructionIndex}-${weltIndex}`}
                                      value={welt}
                                      checked={constructionItem.welt.includes(
                                        welt
                                      )} // Check if welt is selected
                                      onChange={(e) => {
                                        const updatedSoles = [...product.soles];
                                        const construction =
                                          updatedSoles[soleIndex].constructions[
                                            constructionIndex
                                          ];
                                        if (e.target.checked) {
                                          construction.welt.push(welt); // Add welt
                                        } else {
                                          construction.welt =
                                            construction.welt.filter(
                                              (w) => w !== welt
                                            ); // Remove welt
                                        }
                                        setProduct({
                                          ...product,
                                          soles: updatedSoles,
                                        });
                                      }}
                                    />
                                    <label
                                      htmlFor={`welt-${soleIndex}-${constructionIndex}-${weltIndex}`}
                                      className="mr-2 flex justify-between items-center w-full"
                                    >
                                      {welt.replace(/_/g, " ")}
                                    </label>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )
                      )}

                      {/* Add More Constructions Button */}
                      <div className="px-2 mx-2">
                        <button
                          onClick={() => handleAddMore("Constructions")}
                          className="border-2 border-dashed text-black rounded-md p-2 w-full border-black cursor-pointer"
                        >
                          <span>+ Add More</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ))}

              {/* Add More Soles Button */}
              <div className="p-2 w-1/4">
                <button
                  onClick={() => handleAddMore("Soles")}
                  className="border-2 border-dashed text-blue-400 rounded-md p-2 w-full border-blue-300 cursor-pointer"
                >
                  <span>+ Add More</span>
                </button>
              </div>
            </div>

            <div className="flex justify-end py-3">
              <button
                className="bg-green-400 text-white border-green-400 shadow-lg hover:shadow-sm rounded-md p-2 flex justify-between items-center text-sm"
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingPage;
