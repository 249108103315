import React from "react";
import { FaInstagram } from "react-icons/fa";

function Footer() {
  return (
    <>
      <div className="bg-black py-8 flex justify-around text-white">
        <span className="text-sm">
          © 2024 BUWCH Lifestyles. All rights reserved
        </span>
        <span>
          <FaInstagram size={20} />
        </span>
      </div>
    </>
  );
}

export default Footer;
