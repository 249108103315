import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa6";
import { TbEdit } from "react-icons/tb";

const SelectDropdown = ({ options, selectedItem, onItemChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  const handleSelect = (color) => {
    onItemChange(color);
    setIsOpen(false);
  };

  // Function to handle clicks outside the popup
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Effect to add and clean up the event listener
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const displayItem =
    typeof selectedItem === "string" && selectedItem !== ""
      ? selectedItem
      : "Select Option";

  return (
    <div className="relative">
      <button
        className="border-2 rounded-md p-2 cursor-pointer w-full flex justify-between items-center"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="flex items-center">
          <span className="ml-2">{displayItem?.replace(/_/g, " ")}</span>
        </div>
        <span>
          <FaChevronDown size={10} />
        </span>
      </button>
      {isOpen && (
        <div
          className="absolute bg-white border rounded-md w-full z-10"
          ref={popupRef}
        >
          {Object.entries(options)?.map(([item], index) => (
            <div
              key={index}
              className="flex justify-between w-full items-center p-2 hover:bg-gray-200 cursor-pointer"
              onClick={() => handleSelect(item)}
            >
              {item?.replace(/_/g, " ")}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectDropdown;
