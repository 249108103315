import React, { useState, useRef, useEffect } from "react";
import { FaChevronDown } from "react-icons/fa6";

const SelectDropdown2 = ({ options, selectedItem, onItemChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const popupRef = useRef(null);

  const handleSelect = (color) => {
    onItemChange(color);
    setIsOpen(false);
  };

  // Function to handle clicks outside the popup
  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Effect to add and clean up the event listener
  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpen]);

  const displayItem = selectedItem !== "" ? selectedItem : "Select Option";

  return (
    <div className="relative">
      <button
        className="border-2 rounded-md p-2 cursor-pointer w-full flex justify-between items-center"
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <div className="flex items-center">
          <span className="ml-2">{displayItem?.replace(/_/g, " ")}</span>
        </div>
        <span>
          <FaChevronDown size={10} />
        </span>
      </button>
      {isOpen && (
        <div
          className="absolute bg-white border rounded-md w-full z-10"
          ref={popupRef}
        >
          {Object.entries(options)?.map(([sole, price], index) => (
            <div key={index}>
              {/* Group header */}
              <div className="font-bold p-2 bg-gray-100">
                {sole?.replace(/_/g, " ")}
              </div>

              {/* Check if price is an object to render variants */}
              {typeof price === "object" ? (
                Object.entries(price)?.map(([variant], variantIndex) => (
                  <div
                    key={`${sole}-${variantIndex}`}
                    className="w-full p-2 hover:bg-gray-200 cursor-pointer"
                    onClick={() => handleSelect(`${sole}/${variant}`)}
                  >
                    {variant?.replace(/_/g, " ")}
                  </div>
                ))
              ) : (
                // Render the sole if price is not an object
                <div
                  key={`${sole}-${index}`}
                  className="w-full p-2 hover:bg-gray-200 cursor-pointer"
                  onClick={() => handleSelect(sole)}
                >
                  {sole?.replace(/_/g, " ")}
                </div>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SelectDropdown2;
